import React, { useMemo } from 'react';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { Column } from '../../components/layout/grid';
import { ConfigService } from '../../services/config-service';
import { Header } from '../../components/layout/page-header';
import { Spacer } from '../../components/layout/spacers';
import { Template } from '../../components/template';
import { ActivityProgress } from '../../components/activity-progress';
import styles from '../../styles/templates/checklist-template.module.scss';
import { ChecklistCategory } from './category';
import { withChecklist } from './with-checklist';

export const CategoriesContainer = ({ children, edge }) => (
  <Column className={styles.wrapper} edge={edge}>
    {children}
  </Column>
);

const Component = React.memo(({ checklist = [] }) => {
  const {
    pageTitle,
    subtitle,
    headerImage,
    category,
    activityProgress = {},
    listType,
    pageHeader: configHeader
  } = ConfigService.get('CHECKLIST', {});
  const { show } = activityProgress;

  const pageHeader = useMemo(() => {
    const oldConfig = { heading: pageTitle, subheading: subtitle, category: category, image: headerImage };

    return ObjectShim.mergeDeep(oldConfig, configHeader);
  }, [pageTitle, subtitle, category, headerImage, configHeader]);

  return (
    <>
      <Header
        className={styles.header}
        heading={pageHeader?.heading}
        subheading={pageHeader?.subheading}
        category={pageHeader?.category}
        image={pageHeader?.image}
        type={pageHeader?.type}
        backgroundPosition={pageHeader?.backgroundPosition}
        showBackButton={pageHeader?.showBackButton}
        withKeyline
      />
      {show && <ActivityProgress />}
      <Template className={styles.body}>
        <Spacer type='top' />
        <CategoriesContainer edge>
          {checklist.map((category, i) => (
            <ChecklistCategory
              key={`${category.heading}_${i}`}
              listType={listType}
              maxItems={category.maxItems}
              {...category}
            />
          ))}
        </CategoriesContainer>
        <Spacer type='bottom' />
      </Template>
    </>
  );
});

export const Checklist = withChecklist(Component);
