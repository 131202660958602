import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useFavorite } from '@apps/firsthomecoach/src/helpers/status-data/use-favorite';
import { toggleModal } from '@apps/firsthomecoach/src/store/reducers/modals';
import { useScrollRestoration } from '@apps/firsthomecoach/src/components/scroll-position/hooks/use-scroll-restoration';
import { TYPE_TO_ACTIVITY_KEY } from '../../helpers/content-interaction/constants';
import CardComponent from './component';
import { DEFAULT_REPEATER_FOR_SAVING } from './constants';

const Card = props => {
  const {
    id,
    title,
    subTitle,
    excerpt,
    label,
    slug,
    ownKey,
    href,
    image,
    type,
    className,
    videoUrl,
    repeaterKey: sourceRepeaterKey,
    onCardInView,
    isDisplayCardSaveButton,
    isDisplayCardLabel
  } = props;

  const { push, location } = useHistory();

  const { rememberScrollPosition } = useScrollRestoration();

  const dispatch = useDispatch();

  const onClose = () => dispatch(toggleModal(false, 'overlay', {}));

  // Unmount overlay in case route changes.
  useEffect(() => {
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key, location.pathname]);

  const handleRedirect = useCallback(() => {
    if (type === TYPE_TO_ACTIVITY_KEY.video) {
      return dispatch(toggleModal(true, 'overlay', { videoUrl, ownKey }));
    }

    rememberScrollPosition();

    return push(href);
  }, [dispatch, href, push, type, videoUrl, ownKey, rememberScrollPosition]);

  const targetRepeaterKey = DEFAULT_REPEATER_FOR_SAVING;

  const { iconName, handleAction } = useFavorite({
    ownKey,
    slug,
    type,
    targetRepeaterKey,
    sourceRepeaterKey,
    id,
    title
  });

  const onShowHandler = () => onCardInView({ type, ownKey, slug, id, title });

  return (
    <CardComponent
      type={type}
      href={href}
      slug={slug}
      image={image}
      videoUrl={videoUrl}
      title={title}
      subTitle={subTitle}
      excerpt={excerpt}
      label={label}
      handleRedirect={handleRedirect}
      handleAction={handleAction}
      actionIcon={iconName}
      showIcon={isDisplayCardSaveButton}
      showLabel={isDisplayCardLabel}
      className={className}
      onShowHandler={onShowHandler}
    />
  );
};

export default Card;
