import React from 'react';
import { compose, withPropsOnChange } from 'recompose';
import { SegmentedButton } from '../../../components/buttons/segmented-button';
import { withInputLabel } from '../../../components/inputs/input';
import { withAdvancedInformer } from '../../../components/inputs/input-field';
import { withButton, withContainer } from './components/input-field';
import { withPreSubmitButton } from './components/pre-submit-button/with-pre-submit-button';

const Input = compose(
  withContainer,
  withButton,
  withInputLabel,
  withAdvancedInformer,
  withPreSubmitButton,
  withPropsOnChange(['items'], ({ items }) => ({
    items: items.map(item => ({ ...item, value: item.value ?? item.label }))
  }))
)(SegmentedButton);

export const QuestionSegmentedButton = React.memo(
  ({
    value,
    buttonLabel,
    items,
    onChange,
    onInput,
    onSubmit,
    loading,
    disabled,
    preSubmitButton,
    buttonType,
    label,
    helpModal,
    isOptional,
    informer
  }) => (
    <Input
      value={value}
      label={label}
      buttonLabel={buttonLabel}
      buttonType={buttonType}
      items={items}
      disabled={disabled}
      onChange={onChange}
      onTextChange={onInput}
      onSubmit={onSubmit}
      loading={loading}
      preSubmitButton={preSubmitButton}
      informer={helpModal}
      isOptional={isOptional}
      advancedInformer={informer}
      isInput
    />
  )
);
