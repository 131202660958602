import { parse } from 'query-string';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useMountEffect from '@packages/hooks/core/use-mount-effect';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { buildFeedParams, getCategories, matchFeedCategories, matchFeedStatuses } from '../../helpers/feed';
import { ConfigService } from '../../services/config-service';
import { fetchRepeaterByKey } from '../../store/reducers/repeaters';
import { getStaticData } from '../../store/reducers/static-data';
import ContentFeedTemplate from '../../templates/content-feed';
import { CARD_LOCATION_PAGE_TYPES } from '../../helpers/repeaters/constants';

const ContentFeed = props => {
  const { getFeedItems } = props;
  const { push, location } = useHistory();
  const { search } = useLocation();
  const params = useMemo(() => parse(search), [search]);

  const pageConfiguration = useMemo(() => {
    const configurationList = ConfigService.get('FEED', {});
    const purePathname = location.pathname.replace('/', '');
    return configurationList[purePathname] || configurationList;
  }, [location]);

  const {
    pageHeader,
    pageAction,
    emptyCategoryText,
    emptyEmptyStateText,
    contentTypes,
    categories: configCategories = [],
    repeaterKey = 'feed',
    isDisplayItemSubTitle,
    isDisplayItemExcerpt,
    isDisplayCardSaveButton,
    isDisplayCardLabel = true,
    filter: filterOptions,
    saveIcon: saveIconOptions
  } = pageConfiguration;

  const categoriesOptions = getCategories(configCategories);
  const feedItems = useSelector(state => state.repeaters.list[repeaterKey]?.items ?? [], shallowEqual);
  const feedLoading = useSelector(state => state.repeaters.loading, shallowEqual);

  const [category, setCategory] = useState(() => matchFeedCategories(categoriesOptions, params?.categories));
  const [savedStatus, setSavedStatus] = useState(() => matchFeedStatuses(params?.savedStatus));

  const prevState = useRef({ category, savedStatus });

  useEffect(() => {
    const category = matchFeedCategories(categoriesOptions, params?.categories);
    const savedStatus = matchFeedStatuses(params?.savedStatus);

    setCategory(category);
    setSavedStatus(savedStatus);
  }, [categoriesOptions, params?.categories, params?.savedStatus]);

  const isActiveStatus = savedStatus === 'saved';

  const handleChangeCategory = useCallback(
    () =>
      ({ key }) => {
        push(`${buildFeedParams({ category: key, savedStatus, contentTypes })}`);
      },
    [contentTypes, push, savedStatus]
  );

  const handleToggleStatus = useCallback(() => {
    push(`${buildFeedParams({ category, savedStatus: isActiveStatus ? 'both' : 'saved', contentTypes })}`);
  }, [push, category, isActiveStatus, contentTypes]);

  // Fetching categories options for a dropdown.
  useMountEffect(() => {
    const fetchData = async () => {
      const category = matchFeedCategories(categoriesOptions, params?.categories);
      const savedStatus = matchFeedStatuses(params?.savedStatus);

      prevState.current = { ...prevState.current, category, savedStatus };
      getFeedItems({ key: repeaterKey, encodedParams: buildFeedParams({ category, savedStatus, contentTypes }) });
    };

    fetchData();
  });

  // Fetching content items based on URL parameters.
  useEffect(() => {
    // Prevents several requests on mount
    if (prevState.current.category !== category || prevState.current.savedStatus !== savedStatus) {
      prevState.current = { ...prevState.current, category, savedStatus };
      getFeedItems({ key: repeaterKey, encodedParams: buildFeedParams({ category, savedStatus, contentTypes }) });
    }
  }, [getFeedItems, category, savedStatus, contentTypes, repeaterKey]);

  return (
    <ContentFeedTemplate
      repeaterKey={repeaterKey}
      emptyCategoryText={emptyCategoryText}
      emptyEmptyStateText={emptyEmptyStateText}
      pageHeader={pageHeader}
      pageAction={pageAction}
      feedItems={feedItems}
      feedLoading={feedLoading}
      selectedCategory={category}
      categoriesOptions={categoriesOptions}
      isActiveStatus={isActiveStatus}
      handleChangeCategory={handleChangeCategory}
      handleToggleStatus={handleToggleStatus}
      filterOptions={filterOptions}
      saveIconOptions={saveIconOptions}
      pageType={CARD_LOCATION_PAGE_TYPES.ContentFeed}
      isDisplayItemSubTitle={isDisplayItemSubTitle}
      isDisplayItemExcerpt={isDisplayItemExcerpt}
      isDisplayCardSaveButton={isDisplayCardSaveButton}
      isDisplayCardLabel={isDisplayCardLabel}
    />
  );
};

export default connect(null, { getCategoriesList: getStaticData, getFeedItems: fetchRepeaterByKey })(ContentFeed);
