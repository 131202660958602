import Loadable from 'react-loadable';
import { QUESTION } from '@apps/firsthomecoach/src/helpers/navigation';
import { asTemplate } from '@apps/firsthomecoach/src/components/layout/with-layout';
import { Loader } from '@apps/firsthomecoach/src/components/with-loader';

export const Question = Loadable({
  loader: () =>
    import(/* webpackChunkName: "question"*/ '@apps/firsthomecoach/src/pages/question').then(module => module.Question),
  loading: Loader
});

export const questionsRoutes = [
  {
    to: '/(checklist|account|market-place)/:activity/:slug/:identifier?',
    component: asTemplate(QUESTION)(Question)
  }
];
