import { legalPageRoutes } from '@apps/firsthomecoach/src/routes/legal-page';
import { statusPageRoutes } from '@apps/firsthomecoach/src/routes/status-page';
import { contentFeedRoutes } from '@apps/firsthomecoach/src/routes/content-feed';
import { tourPageRoutes } from '@apps/firsthomecoach/src/routes/tour-page';
import { accountsRoutes } from '@apps/firsthomecoach/src/routes/account';
import { dashboardRoutes } from './dashboard';
import { checklistRoutes } from './checklist';
import { questionsRoutes } from './questions';
import { articlesRoutes } from './articles';
import { customPageRoutes } from './custom-page';

export const routes = [
  ...articlesRoutes,
  ...checklistRoutes,
  ...accountsRoutes,
  ...customPageRoutes,
  ...tourPageRoutes,
  ...questionsRoutes,
  ...dashboardRoutes,
  ...legalPageRoutes,
  ...statusPageRoutes,
  ...contentFeedRoutes
];
