import { Checklist } from '@apps/firsthomecoach/src/pages/checklist';
import { asTemplate } from '@apps/firsthomecoach/src/components/layout/with-layout';
import { CHECKLIST } from '@apps/firsthomecoach/src/helpers/navigation';

export const checklistRoutes = [
  {
    to: '/checklist',
    component: asTemplate(CHECKLIST)(Checklist),
    hideOn: 'lgAndUp'
  }
];
