import { Column } from '@packages/ui-kit/core/grid';
import PropTypes from 'prop-types';
import React from 'react';
import styles from '../../../styles/widgets/feature-fit-v2/ff-kpi-list.module.scss';
import { Item as KPIListItem, KPIList } from '../../../widgets/future-fit-v2/guidance/components/kpi-list';
import UpdateBlock from './update-block';

export const KPIDetail = ({
  isExternal,
  isUpdateLabelOff,
  formattedDate,
  updateLabel = 'Answers last updated:',
  updateAction,
  updateLinkTitle,
  buttonType,
  kpiItems
}) => {
  return (
    <Column className={styles.scorecard}>
      <KPIList className={styles['progress-container']} items={kpiItems} />
      {!isExternal && (
        <UpdateBlock
          isUpdateLabelOff={isUpdateLabelOff}
          updateAction={updateAction}
          updateLinkTitle={updateLinkTitle}
          buttonType={buttonType}
          formattedDate={formattedDate}
          updateLabel={updateLabel}
        />
      )}
    </Column>
  );
};

KPIDetail.propTypes = {
  isExternal: PropTypes.bool.isRequired,
  kpiItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: KPIListItem.propTypes.label,
      checked: KPIListItem.propTypes.checked
    })
  ).isRequired,
  formattedDate: PropTypes.string.isRequired,
  updateLabel: PropTypes.string,
  updateAction: PropTypes.func,
  updateLinkTitle: PropTypes.string,
  isUpdateLabelOff: PropTypes.bool,
  messagesFF: PropTypes.shape({
    edge: PropTypes.string,
    data: PropTypes.shape({
      positive: PropTypes.string.isRequired,
      negative: PropTypes.string.isRequired
    })
  })
};

KPIDetail.displayName = 'KPIDetails';
