import React from 'react';
import classnames from 'classnames';
import styles from '../styles/components/informer.module.scss';
import { PurifiedHtmlContainer } from './layout/purified-html-container';

export const Informer = React.memo(({ icon, children, className }) => (
  <div className={classnames(className, styles.container)}>
    <div className={styles.icon}>{icon}</div>
    <PurifiedHtmlContainer renderAs='span' html={children} className={styles.text} parse={false} />
  </div>
));
