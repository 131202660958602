import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  renderNothing,
  withHandlers,
  withProps,
  withPropsOnChange,
  withState
} from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { withCancellableHandlers } from '../../pages/custom-page/with-cancellable-handlers';
import { INPUT_INFORMER_TYPES } from '../../pages/question/helpers/constants';
import { toggleModal } from '../../store/reducers/modals';
import { evaluateTextContentByScenarios } from '../../helpers/workers/evaluate-text-content-by-scenarios';
import { withStopPropagation } from '../../helpers/hocs/with-stop-propagation';
import { withUserStateData } from '../../helpers/hocs/with-user-state-data';
import { BUTTON_TYPES } from '../../helpers/buttons/constants';
import { pickProps } from '../../helpers/hocs/pick-props';
import styles from '../../styles/components/inputs/input-informer.module.scss';
import { PageAction } from '../page-actions-section/components/page-action';
import { Informer } from '../informer';
import { AffiliateIcon } from '../icons/affiliate-icon';

/**
 * @typedef {Object} ScenarioData
 * @property {string} icon - icon
 * @property {string} title - title
 * @property {string} body - a html-like string
 * @property {string} buttonLabel
 * @property {string} buttonAriaLabel
 * @property {string} buttonType
 */

/**
 * @param {ScenarioData} data
 * @return {JSX.Element}
 */
const Component = React.memo(({ data }) => (
  <Informer icon={<AffiliateIcon name={data.icon} />} className={styles.container}>
    {data.body}
  </Informer>
));

/**
 * @param {ScenarioData} data
 * @return {JSX.Element}
 */
const ModalInformerComponent = React.memo(({ label, ariaLabel, type, icon, onClick }) => (
  <PageAction
    iconName={icon}
    onClick={onClick}
    ariaLabel={ariaLabel}
    type={type || BUTTON_TYPES.diminished}
    className={styles.button}
    isButton
  >
    {label}
  </PageAction>
));

export const withModalInformer = Component =>
  compose(
    connect(null, { toggleModal }),
    withHandlers({
      onClick:
        ({ data, toggleModal }) =>
        event => {
          event.preventDefault();
          toggleModal(true, 'help', { buttonEnabled: false, body: data.body, title: data.title });
        }
    }),
    withStopPropagation('onClick'),
    pickProps(['onClick', 'className', 'ariaLabel', 'label', 'type', 'icon'])
  )(Component);

const ModalInformer = compose(
  //Merge props data and config data
  withPropsOnChange(['data'], ({ data, ariaLabel }) => ({
    ariaLabel: data.buttonAriaLabel ?? ariaLabel,
    label: data.buttonLabel,
    type: data.buttonType,
    icon: data.icon
  })),
  withModalInformer
)(ModalInformerComponent);

const InputInformer = compose(
  withProps(({ type }) => ({ type: type || INPUT_INFORMER_TYPES.static })),
  withState('data', 'setInformerData', {}),
  withUserStateData,
  withCancellableHandlers({
    getAppropriateInformerScenario:
      ({ userState, scenarios }) =>
      async () =>
        /**
         * @return {Promise<ScenarioData>}
         */
        await evaluateTextContentByScenarios({
          userState,
          scenarios: scenarios,
          fieldNames: ['body'],
          restFields: ['icon', 'title', 'buttonLabel', 'buttonAriaLabel', 'buttonType']
        })
  }),
  lifecycle({
    async componentDidMount() {
      const { getAppropriateInformerScenario, scenarios, setInformerData } = this.props;

      if (scenarios) {
        const informerScenario = await getAppropriateInformerScenario();
        setInformerData(informerScenario);
      }
    }
  }),
  branch(({ data }) => ObjectShim.isEmpty(data), renderNothing),
  branch(({ type }) => type === INPUT_INFORMER_TYPES.modal, renderComponent(ModalInformer))
)(Component);

InputInformer.propTypes = {
  type: PropTypes.oneOf(Object.values(INPUT_INFORMER_TYPES)),
  scenarios: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      body: PropTypes.string.isRequired,
      icon: PropTypes.string,
      title: PropTypes.string,
      buttonLabel: PropTypes.string,
      buttonAriaLabel: PropTypes.string,
      buttonType: PropTypes.string
    })
  }).isRequired
};

InputInformer.displayName = 'InputInformer';

export { InputInformer };
