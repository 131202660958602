import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { KnowledgeBase } from '../../templates/knowledge-base';
import { Footer } from '../../components/layout/page-footer';
import { withMetaTags } from '../../components/meta-tags';

const SEO_PAGE_DATA_NAME = 'knowledgebase';

export const ArticleListComponent = () => {
  return (
    <>
      <KnowledgeBase />
      <Footer pageType='knowledgebase' />
    </>
  );
};

const mapStateToProps = state => ({
  metaData: state.content.seoPages[SEO_PAGE_DATA_NAME]
});

export const ArticleList = compose(connect(mapStateToProps), withMetaTags(SEO_PAGE_DATA_NAME))(ArticleListComponent);
