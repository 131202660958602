import { compose } from 'redux';
import ReactDOMServer from 'react-dom/server';
import { Informer as CoachExplainer } from '../../../components/informer';
import styles from '../../../styles/components/coach-explainer.module.scss';
import { getAffiliateIconSync } from '../../layout/get-afiliate-icon';
import { getElementFromHtmlString } from './helpers';

export const changeExplainer = element => {
  const explainers = element.querySelectorAll('.coach-explainer');

  if (explainers.length) {
    explainers.forEach(explainer => {
      const [, icon] = explainer.classList;

      const jsx = (
        <CoachExplainer icon={getAffiliateIconSync(icon)} className={styles.container}>
          {explainer.innerHTML}
        </CoachExplainer>
      );

      const component = compose(getElementFromHtmlString, ReactDOMServer.renderToStaticMarkup)(jsx);

      explainer.parentNode.replaceChild(component, explainer);
    });
  }

  return element;
};
