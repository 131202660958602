import React, { useCallback } from 'react';
import classnames from 'classnames';
import styles from '../../styles/components/input-label.module.scss';
import { InformerIcon } from '../icons/informer-icon';
import { Image } from '../images/image';
import { Link } from '../link';
import { withRequiredIndicator } from './required-indicator';
import { Explainer } from './form-explainer';
import { withModalInformer } from './input-informer';

export const Informer = withModalInformer(InformerIcon);

Informer.displayName = 'Informer';

const PureLabel = React.memo(({ htmlFor, withPreventingDefault, className, children }) => {
  // Prevents multiple updates using in a combination with external parent click, such as withOptionRowClick().
  const preventDefault = useCallback(
    e => {
      if (withPreventingDefault) {
        e.preventDefault();
      }
    },
    [withPreventingDefault]
  );

  return (
    <label htmlFor={htmlFor} onClick={preventDefault} className={classnames(styles.label, className)}>
      {children}
    </label>
  );
});

PureLabel.displayName = 'PureLabel';

const LabelWithRequiredIndicator = withRequiredIndicator(PureLabel);

export const Label = React.memo(({ htmlFor, withPreventingDefault, informer, className, isRequired, children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={classnames({ [styles['no-informer']]: !informer })}>
        {informer && (
          <Informer ariaLabel={`More information about ${children}`} className={styles.informer} data={informer} />
        )}
      </div>
      <div className={className}>
        <LabelWithRequiredIndicator
          htmlFor={htmlFor}
          isRequired={isRequired}
          withPreventingDefault={withPreventingDefault}
        >
          {children}
        </LabelWithRequiredIndicator>
      </div>
    </div>
  );
});

Label.displayName = 'Label';

export const InputLabel = React.memo(({ layout, label, children, ...props }) => (
  <div className={classnames(styles.container, layout)}>
    {label || props.informer ? <Label {...props}>{label}</Label> : null}
    {children}
  </div>
));

InputLabel.displayName = 'InputLabel';

const InputSubLabel = React.memo(({ subLabel }) => <span className={styles['sub-label']}>{subLabel}</span>);

InputSubLabel.displayName = 'InputSubLabel';

const InputOptionLink = React.memo(({ onClick, label, checked }) => (
  <Link
    className={classnames(styles.link, { [styles['link-inside-checked-option']]: checked })}
    onClick={onClick}
    isButton
  >
    {label}
  </Link>
));
InputOptionLink.displayName = 'InputOptionLink';

const InputOptionLinks = React.memo(({ links, checked }) => (
  <div className={styles['links-container']}>
    {links.map(({ onClick, label }) => (
      <InputOptionLink key={label} onClick={onClick} label={label} checked={checked} />
    ))}
  </div>
));

InputOptionLinks.displayName = 'InputOptionLinks';

export const InputOptionLabel = React.memo(
  ({ layout, label, subLabel, image, imageAlt, children, explainer, styling, className, linksConfig, ...props }) => (
    <>
      <div className={classnames(styles.container, layout, styling)}>
        <div className={styles['label-container']}>
          {image && <Image src={image} title={imageAlt ?? label} className={styles.image} />}
          <Label className={classnames(styles['option-label'], className)} {...props}>
            <span>{label}</span>
            {subLabel ? <InputSubLabel className={styles['sub-label']} subLabel={subLabel} /> : null}
          </Label>
        </div>
        {linksConfig?.links?.length ? (
          <InputOptionLinks links={linksConfig.links} checked={linksConfig.checked} />
        ) : null}
        {children}
      </div>
      {explainer ? <Explainer explainerId={`${props.htmlFor}_explainer`}>{explainer}</Explainer> : null}
    </>
  )
);

InputOptionLabel.displayName = 'InputOptionLabel';
