import { Post } from '@apps/firsthomecoach/src/routes/articles';
import { ArticleList } from '@apps/firsthomecoach/src/pages/knowledge-base';
import { asTemplate } from '@apps/firsthomecoach/src/components/layout/with-layout';
import { ARTICLE, ARTICLES } from '@apps/firsthomecoach/src/helpers/navigation';

export const articlesRoutes = [
  {
    to: '/articles/:slug',
    component: asTemplate(ARTICLE)(Post)
  },
  {
    to: '/articles',
    component: asTemplate(ARTICLES)(ArticleList),
    hideOn: 'lgAndUp'
  }
];
