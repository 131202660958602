import { Button } from '@apps/firsthomecoach/src/components/buttons/button-with-icon';
import { AffiliateIcon } from '@apps/firsthomecoach/src/components/icons/affiliate-icon';
import { PurifiedHtmlContainer } from '@apps/firsthomecoach/src/components/layout/purified-html-container';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { TYPE_TO_ACTIVITY_KEY } from '../../helpers/content-interaction/constants';
import styles from '../../styles/widgets/card.module.scss';
import IntersectionVisible from '../../components/intersection-visibility';

const CardComponent = React.memo(props => {
  const {
    title,
    subTitle,
    excerpt,
    label,
    image,
    type,
    actionIcon = 'arrow-right',
    handleAction,
    className,
    handleRedirect,
    onShowHandler,
    showIcon,
    showLabel
  } = props;

  const isVideo = type === TYPE_TO_ACTIVITY_KEY.video;
  const showFooter = showLabel || showIcon;

  return (
    <div onClick={handleRedirect} className={classnames(styles.link, className)}>
      <div className={styles.card}>
        <div className={styles.header}>
          {isVideo ? <AffiliateIcon className={styles.play} name='play' /> : null}
          <img className={styles.content} src={image} alt={image} />
        </div>
        <div className={styles.body}>
          <h3 className={styles.heading}>{title}</h3>
          {subTitle && <PurifiedHtmlContainer renderAs='div' className={styles.subTitle} html={subTitle} />}
          {excerpt && <PurifiedHtmlContainer renderAs='div' className={styles.excerpt} html={excerpt} />}
        </div>
        {showFooter ? (
          <div className={styles.footer}>
            {showLabel ? (
              <>
                <AffiliateIcon className={styles.labelIcon} name={type} />
                <span className={styles.label}>{label}</span>
              </>
            ) : null}
            {showIcon && (
              <Button className={styles.action} onClick={handleAction} icon={false}>
                <AffiliateIcon className={styles.actionIcon} name={actionIcon} />
              </Button>
            )}
          </div>
        ) : null}
      </div>
      <IntersectionVisible onShow={onShowHandler} once />
    </div>
  );
});

CardComponent.displayName = 'CardComponent';

CardComponent.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['article', 'video', 'story', 'blog', 'activity', 'project']).isRequired,
  href: PropTypes.string.isRequired,
  ownKey: PropTypes.string,
  slug: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  excerpt: PropTypes.string,
  label: PropTypes.string,
  actionIcon: PropTypes.string,
  handleAction: PropTypes.func.isRequired,
  handleRedirect: PropTypes.func.isRequired,
  showIcon: PropTypes.bool,
  showLabel: PropTypes.bool,
  onShowHandler: PropTypes.func.isRequired
};

export default CardComponent;
