import { Header } from '@apps/firsthomecoach/src/components/layout/page-header';
import { Column } from '@packages/ui-kit/core/grid';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Footer } from '../../components/layout/page-footer';
import { MetaTags } from '../../components/meta-tags';
import { PageActionsSection } from '../../components/page-actions-section';
import { TBody, Template, THeading, TSubHeading } from '../../components/template';
import { lastAnsweredDateByActivityStatuses } from '../../pages/dashboard/widgets/future-fit-v2/helpers';
import { ConfigService } from '../../services/config-service';
import { sendQuestionSectionInfo } from '../../store/reducers/user-attributes/actions';
import styles from '../../styles/templates/guidance-template.module.scss';
import AccordionActions from './components/accordion-actions';
import CoachLikeBlock from './components/CoachLikeBlock';
import { KPIDetail } from './components/kpi-detail';

export const MAXIMUM_GOAL_SCORE = 3;

const GuidanceTemplate = React.memo(props => {
  const {
    pageName,
    pageHeader,
    pageActions,
    text,
    messagesFF,
    activityKeys,
    activityStatuses,
    updateMyAnswers,
    congratulationsMessage: lowScoreCongratulationsMessage
  } = ConfigService.get('GUIDANCE', {});

  const { statuses, isExternal, brokerName, guidance, kpiItems, activities, isHPG, score } = props;
  const {
    heading,
    subHeading,
    body,
    body2,
    link: sectionLink,
    congratulationsMessage: fullScoreCongratulationsMessage
  } = guidance;

  const isAllowedActivitiesExist = !!activities.length;
  const formattedDate = lastAnsweredDateByActivityStatuses({ statuses, activityKeys });
  const isFullScore = score === MAXIMUM_GOAL_SCORE;
  const congratulationsMessage = useMemo(() => {
    if (isFullScore) {
      return { ...fullScoreCongratulationsMessage };
    }

    if (!isExternal) {
      return { ...lowScoreCongratulationsMessage, link: sectionLink || lowScoreCongratulationsMessage?.link };
    }

    return null;
  }, [isExternal, fullScoreCongratulationsMessage, isFullScore, lowScoreCongratulationsMessage, sectionLink]);

  const dispatch = useDispatch();
  const history = useHistory();
  const onEditQuestionSection = useCallback(
    to => async () => {
      await dispatch(sendQuestionSectionInfo({ isSectionEdition: true }, null));
      history.push(to);
    },
    [dispatch, history]
  );

  return (
    <>
      <MetaTags name={pageName} />
      {pageHeader?.isVisible && (
        <Header
          className={styles.header}
          heading={heading}
          subheading={subHeading}
          category={pageHeader?.category}
          type={pageHeader?.type}
          backgroundPosition={pageHeader?.backgroundPosition}
          showBackButton={pageHeader?.showBackButton}
        >
          {!isExternal && <PageActionsSection actionsList={pageActions} />}
        </Header>
      )}
      <Template className={styles.grid}>
        <Column className={styles.item}>
          {isExternal && brokerName ? <THeading>{brokerName}</THeading> : null}
          {isHPG ? (
            <KPIDetail
              isExternal={isExternal}
              formattedDate={formattedDate}
              messagesFF={messagesFF}
              kpiItems={kpiItems}
              updateAction={onEditQuestionSection(sectionLink)}
              updateLinkTitle={updateMyAnswers?.label || 'Update my answers'}
              buttonType={updateMyAnswers?.buttonType || 'major'}
            />
          ) : null}
          {!!body ? (
            <div>
              <THeading>{text.backgroundLabel}</THeading>
              <TBody className={styles['reset-body']} html={body} />
            </div>
          ) : null}
          {isAllowedActivitiesExist && !isFullScore ? (
            <div>
              <THeading>{text.nextActionsLabel}</THeading>
              <TSubHeading>{text.nextActionSubHeading}</TSubHeading>
              <AccordionActions
                className={styles.accordion}
                actions={activities}
                activityStatuses={activityStatuses}
                isExternal={isExternal}
              />
            </div>
          ) : null}
          {!!body2 ? (
            <div>
              <THeading>{text.evidenceLabel}</THeading>
              <TBody html={body2} />
            </div>
          ) : null}
          {congratulationsMessage ? (
            <CoachLikeBlock
              linkDisplay={congratulationsMessage.linkDisplay}
              text={congratulationsMessage.text}
              label={congratulationsMessage.label}
              link={congratulationsMessage.link}
              isExternal={congratulationsMessage.isExternal}
              onEditQuestionSection={onEditQuestionSection}
            />
          ) : null}
        </Column>
      </Template>
      <Footer pageType='guidance' />
    </>
  );
});

GuidanceTemplate.propTypes = {
  guidance: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    subHeading: PropTypes.string.isRequired,
    body: PropTypes.string,
    body2: PropTypes.string,
    link: PropTypes.string,
    congratulationsMessage: PropTypes.shape(CoachLikeBlock.propTypes)
  }),
  kpiItems: KPIDetail.propTypes.kpiItems,
  activities: AccordionActions.propTypes.actions,
  isExternal: PropTypes.bool.isRequired,
  score: PropTypes.number,
  brokerName: PropTypes.string,
  isHPG: PropTypes.bool,
  statuses: PropTypes.object.isRequired
};

GuidanceTemplate.displayName = 'GuidanceTemplate';

export default GuidanceTemplate;
