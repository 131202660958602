import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { evaluateCondition } from '../parcers/parce-condition';
import { sortByWeight } from '../array';
import { evaluate } from './evaluate-message';

export const evaluateScenariosCondition = async ({ scenarios, userState, saveScenarioKeyAs }) => {
  const evaluatedScenarios = [];

  for (const [key, scenario] of Object.entries(scenarios)) {
    // Anyway returns true / false.
    evaluatedScenarios.push({
      ...scenario,
      ...(saveScenarioKeyAs && { [saveScenarioKeyAs]: key }),
      state: scenario.displayCondition ? await evaluateCondition(userState, scenario.displayCondition, {}) : true
    });
  }

  return sortByWeight(evaluatedScenarios).find(({ state }) => !!state);
};

export const evaluateTextContentByScenarios = async ({
  userState,
  scenarios,
  fieldNames,
  defaultData,
  restFields = []
}) => {
  // All evaluated fields.
  const evaluatedFields = {};

  if (!ObjectShim.isObject(scenarios) || ObjectShim.isEmpty(scenarios)) {
    return defaultData ?? evaluatedFields;
  }

  const firstTruthyCondition = await evaluateScenariosCondition({ scenarios, userState });

  for (const fieldName of fieldNames) {
    try {
      if (firstTruthyCondition?.[fieldName]) {
        evaluatedFields[fieldName] = await evaluate(firstTruthyCondition[fieldName], userState);
      }
    } catch (e) {
      // Ignoring broken condition and keeping evaluatedFields object without any changes.
      // eslint-disable-next-line no-console
      console.log('ERROR on scenario evaluation:', e);
    }
  }

  for (const field of restFields) {
    try {
      if (firstTruthyCondition?.[field]) {
        evaluatedFields[field] = firstTruthyCondition[field];
      }
    } catch (e) {
      // Ignoring broken condition and keeping evaluatedFields object without any changes.
      // eslint-disable-next-line no-console
      console.log('ERROR on scenario evaluation:', e);
    }
  }

  return evaluatedFields;
};
