import React from 'react';
import { compose } from 'recompose';
import { CheckboxGroup } from '../../../components/inputs/checkbox/checkbox-group';
import { withAdvancedInformer, withError } from '../../../components/inputs/input-field';
import { withButton, withContainer } from './components/input-field';
import { withPreSubmitButton } from './components/pre-submit-button/with-pre-submit-button';

const Input = compose(withContainer, withButton, withError, withAdvancedInformer, withPreSubmitButton)(CheckboxGroup);

export const QuestionCheckboxInput = React.memo(
  ({
    value,
    buttonLabel,
    buttonType,
    items,
    onChange,
    onInput,
    onSubmit,
    type,
    disabled,
    error,
    loading,
    preSubmitButton,
    isOptional,
    styling,
    informer
  }) => {
    return (
      <Input
        value={value}
        buttonLabel={buttonLabel}
        buttonType={buttonType}
        items={items}
        disabled={disabled || loading}
        type={type}
        error={error}
        onChange={onChange}
        onTextChange={onInput}
        onSubmit={onSubmit}
        preSubmitButton={preSubmitButton}
        isOptional={isOptional}
        styling={styling}
        advancedInformer={informer}
      />
    );
  }
);
